@import "src/variables";

.container {
  display: flex;
  height: 100%;

  .mainPanel {
    width: calc(100% - 300px);

    @media(max-width: 1000px) {
      width: 100%;

      .isDetailsOpen & {
        display: none;
      }
    }
  }

  .detailsPanel {
    width: 300px;
    border-left: $mt-layout-border;

    @media(max-width: 1000px) {
      display: none;

      .isDetailsOpen & {
        display: flex;
        width: 100%;
        border: none;
      }
    }
  }
}

.placeholder {
  padding: 50px;
}
