.container {
  padding: 80px;

  .btnContainer {
    margin: 10px -5px -5px;

    button {
      margin: 5px;
      padding: 5px 10px;
    }
  }

  .loading {
    justify-content: flex-start;
  }

  .success {
    margin-top: 10px;
  }
}
