@import "src/variables";

.container {
  display: flex;
  align-items: center;
  padding: 20px;

  .avatar {
    border-radius: 16px;
    overflow: hidden;
    width: 32px;
    height: 32px;

    img {
      width: 100%;
    }
  }

  .nameCtn {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .name {
      font-weight: 500;
    }

    .logout {
      font-size: 14px;
      margin-left: 10px;
      text-decoration: underline;
      cursor: pointer;
      transition: $mt-transition;

      &:hover {
        color: $mt-color-turquoise;
      }
    }
  }

}
