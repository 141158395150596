@import "src/variables";

.container {
  position: relative;
  margin-top: 20px;
}

.avatar {
  position: absolute;
  top: 9px;
}

.content {
  display: flex;
  flex-direction: column;
  margin-right: 46px;

  .bubbleCtn {
    display: flex;
    justify-content: flex-end;

    .bubble {
      text-align: center;
      max-width: calc(100% - 60px);
      padding: 14px 14px 13px;
      background: rgb(245, 245, 245);
      border-radius: 6px;
    }

    .isNote {
      background: $mt-color-for-notes;
    }
  }
}