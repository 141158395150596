@import "../variables";

.container {
  display: flex;
  height: calc(100 * var(--vh));
}

.leftPane {
  height: 100%;
  width: 300px;
  background: #f4f5f7;
  border-right: $mt-layout-border;

  display: flex;
  flex-direction: column;

  @media(max-width: $mt-mobile-width) {
    background: transparent;
    display: none;

    .isMobileMenuOpen & {
      display: flex;
      width: 100%;
      border: none;
    }
  }
}

.rightPane {
  height: 100%;
  width: calc(100% - 300px);

  @media(max-width: $mt-mobile-width) {
    width: 100%;

    .isMobileMenuOpen & {
      display: none;
    }
  }

  display: flex;
  flex-direction: column;
}
