@import "src/variables";

.container {
  padding: 0 20px 20px;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .inner {
    display: flex;
    flex-direction: column;
  }
}
