@import "src/variables";

.container {
  position: relative;
  margin-top: 20px;
  opacity: .6; // show semi-transparent
}

.avatar {
  position: absolute;
  top: 9px;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 46px;

  .bubbleCtn {
    display: flex;
    align-items: flex-start;

    .bubble {
      max-width: calc(100% - 69px);

      padding: 14px;
      background: #ebebeb;
      border-radius: 6px;

      .typingIndicator {
        margin-bottom: 5px;
      }

      .draftText {
        word-break: break-word;
        hyphens: none;
        font-size: 14px;
        line-height: 18px;
        font-style: italic;
      }
    }
  }
}
