@import "src/variables";

.container {
  padding: 15px 20px;
  text-decoration: none;
  color: inherit;

  display: flex;
  flex-direction: column;

  &:hover {
    background: rgba(219, 240, 242, .4);
  }

  &.active {
    background: $mt-color-chat-light-bg;
  }
}

.top {
  display: flex;
  align-items: center;

  .visitorAvatar {
    margin-right: 10px;
  }

  .visitorInfo {
    flex: auto;
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 500;
    }

    .location {
      color: #737376;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .date {
    color: #737376;
    font-size: 13px;
  }
}

.bottom {
  margin-top: 15px;
  display: flex;

  .adminAvatar {
    margin-right: 10px;
  }

  .message {
    color: #737376;
    font-size: 15px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
