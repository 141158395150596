@import-normalize;

@import 'variables';
@import 'fonts';

body {
  margin: 0;
  font-family: tt-commons, sans-serif;

  // we can disable scrolling altogether because it will always happen
  // inside some other container further down the tree
  overflow: hidden;

  // global styles
  color: #222;
  font-size: 16px;
  line-height: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea, input[type="text"] {
  -webkit-appearance: none;
}

a {
  transition: $mt-transition;
}
