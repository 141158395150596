@import "src/variables";

.container {
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .title {
    font-weight: bold;
    padding: 10px 20px;
    border-bottom: $mt-layout-border-light;
  }

  .items {
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .item {
      border-bottom: $mt-layout-border-light;
    }
  }
}
