@import "src/variables";

.container {
  padding: 20px;
  border-bottom: $mt-layout-border-light;

  display: flex;
  align-items: center;

  .mobileMenuToggle {
    cursor: pointer;
    padding: 5px 10px;
    transition: $mt-transition;

    display: none;
    @media(max-width: $mt-mobile-width) {
      display: block;
    }

    &:hover {
      color: $mt-color-turquoise;
    }
  }

  .name {
    font-weight: 500;
  }
}
