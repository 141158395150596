// MISC

$mt-transition: .2s;
$mt-layout-border: 1px solid rgba(0,0,0,.2);
$mt-layout-border-light: 1px solid rgba(0,0,0,.1);

$mt-mobile-width: 768px;

// COLORS

$mt-color-dark: #1d2141;
$mt-color-light: #fae3cf;
$mt-color-blue: #1E308D;
$mt-color-turquoise: #99d7dc;
$mt-color-turquoise-dark: #5BB5BD;
$mt-color-dark-blue: #372F97;



// custom chat colors

$mt-color-chat-light-bg: #dbf0f2;
$mt-color-for-notes: rgba(254,237,175,.5);
