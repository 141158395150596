@import "src/variables";

.container {
  width: 95%;
  margin: 0 auto 17px;
  border-radius: 5px;
  border: $mt-layout-border;
  transition: box-shadow 0.2s, border-color 0.2s;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);

  @media(max-width: $mt-mobile-width) {
    margin: 0 auto 7px;
  }

  &.hasFocus {
    border: 1px solid $mt-color-dark-blue;
  }

   &.isNote {
    background-color: $mt-color-for-notes;

     .inputCtn {
       input {
         background-color: transparent;
       }
     }
  }

  .tabCtn {
    padding: 10px 5px 5px;

    a {
      margin-left: 10px;
      border: none;
      background-color: transparent;
      font-size: 16px;
      color: #737376;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      padding: 8px 0 6px;
      margin-right: 5px;
      font-weight: 500;
      outline: none;
      font-family: inherit;

      &.active {
        color: #1E308D;
        border-bottom: 2px solid #1E308D;
      }

      &:hover {
       color: $mt-color-dark-blue;
       border-bottom: 2px solid $mt-color-dark-blue;
       transition: $mt-transition;
      }
    }
  }

  .inputCtn {
    padding: 5px 15px 25px 14px;
    display: flex;
    height: 50px;
    flex-direction: column;

    .input {
      border: none;
      outline: none;
      padding-bottom: 25px;
      padding-top: 10px;
      width: 100%;
      font-size: 14px;
      color: #222;
    }

    .sendBtn {
      border: none;
      border-radius: 5px;
      padding: 10px;
      background: $mt-color-blue;
      color: white;
      font-size: 16px;
      align-self: flex-end;
      margin-top: -25px;
      cursor: pointer;
      font-family: inherit;

      &:hover {
        background-color: $mt-color-dark-blue;
        transition: $mt-transition;
      }
    }
  }
}
