@import "src/variables";

.container {
  position: relative;
  margin-top: 5px;

  &.isFirstInGroup {
    margin-top: 20px;
  }

  &.isNote {
    .content {
      .bubbleCtn {
        .bubble {
          background-color: rgba(254,237,175,.5);
        }
      }
    }
  }
}

.avatar {
  position: absolute;
  top: 9px;
}

.content {
  display: flex;
  flex-direction: column;

  .forVisitor & {
    margin-left: 46px;
  }

  .forAdmin & {
    margin-right: 46px;
  }

  .bubbleCtn {
    display: flex;
    align-items: flex-start;

    .forAdmin & {
      flex-direction: row-reverse;
    }

    .bubble {
      max-width: calc(100% - 69px);

      padding: 14px;
      background: $mt-color-chat-light-bg;
      border-radius: 6px;

      word-break: break-word;
      hyphens: none;
      font-size: 16px;
      line-height: 18px;

      .forVisitor & {
        background: #ebebeb;
      }
    }
  }

  .dateTime {
    display: flex;
    margin-top: 5px;
    font-size: 13px;
    line-height: 20px;
    color: #737376;
    align-self: flex-start;

    .forAdmin & {
      align-self: flex-end;
    }
  }
}
