@import "src/variables";

.container {
  .title {
    padding: 20px;
    font-weight: 500;
    border-bottom: $mt-layout-border-light;
  }

  .content {
    padding: 20px;
  }
}
