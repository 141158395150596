@import "src/variables";

.container {
  overflow: hidden;

  &.forVisitor {
    left: 0;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $mt-color-blue;
    color: white;
  }

  &.forAdmin {
    right: 0;
  }

  img {
    width: 100%;
  }
}
