.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .logo {
    padding: 20px 20px 0 20px;

    img {
      width: 184px;
      height: 31px;
    }
  }

  .loginStatus {
    margin-bottom: 20px;
  }
}
